<template>
  <div>
    <div class="row flex" style="height:100vh">
      <!-- Left side: Login form -->
      <div class="col-md-6 mt-20 p-5">
        <h3 class="text-xl text-center text-oonpayprimary mt-8 mb-8">
          Forgotten Password ?
        </h3>
        <div class="px-4 mb-4">
          <input type="text" v-model="userInput" placeholder="Enter admin email address or phone number"
            class="border border-grey rounded w-full p-3" />
        </div>

        <div class="px-4 mb-10">
          <button @click="login" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">
            <b-spinner v-if="loading" small></b-spinner>

            Send OTP
          </button>
        </div>



      </div>

      <!-- Right side: Image -->
      <div class="d-none d-lg-flex  col-md-6 rightside">

      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      userInput: "",
      loading: false,

    };
  },
 

  methods: {
    login() {
      this.loading = true;
      // eslint-disable-next-line
      const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (emailRegx.test(this.userInput)) {
        return this.$store.dispatch("dashboard/EmailOTP", this.userInput)
          .then(response => {
            this.loading = false;
            if (response.status == 200) {
              return this.$router.push("/new-password")
            }
          })
          .catch(err => {
            this.loading = false;
            this.$toasted.error(err.response.data, { duration: 5000 });
          })
      } else if (this.userInput.match(/^\d{10}$/)) {
        return this.$store.dispatch("dashboard/PhoneOTP", this.userInput)
          .then(response => {
            this.loading = false;

            if (response.status == 200) {
              return this.$router.push("/new-password")
            }
          })
          .catch(err=>{
            this.loading = false;
            this.$toasted.error(err.response.data, { duration: 5000 });
          })
      } else{
        this.loading = false;
        this.$toasted.error("Invalid input", { duration: 5000 });
      } 
    },



  },
};
</script>

<style lang="scss" scoped>
.rightside {
  background-image: url('../../../assets/auth/bg20.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
</style>
